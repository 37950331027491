import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Hidden,
  alpha,
  Tooltip,
  TableSortLabel,
  styled,
  useMediaQuery,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
} from '@mui/material';
import AppsPagination from '@projectK/components/AppsPagination';
import jwtAxios from '@projectK/services/auth/JWT';
import { useIntl } from 'react-intl';
import AppLoader from '@projectK/components/AppLoader';
import { useInfoViewActionsContext } from '@projectK/context/InfoViewContextProvider';
import AppSearchBar from '@projectK/components/AppSearchBar';
import AppsHeader from '@projectK/components/AppsHeader';
import TableHeader from '@projectK/components/TableHeader';
import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import AppScrollbar from '@projectK/components/AppScrollbar';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import { Fonts } from '@projectK/constants';
import productThemes from '@projectK/constants/ProductThemes';
import { useNavigate } from 'react-router-dom';
import { formatDateTimeRange } from '@projectK/modules/apps/DateFormat';
import SubscriptionStartDateDialog from '../SubscriptionStartDateDialog';
import PropTypes from 'prop-types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  padding: 5,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
  '& .icon-btn': {
    border: `solid 1px ${alpha('#F84E4E', 1.0)}`,
    color: alpha('#F84E4E', 1.0),
    padding: 3,
    fontSize: 20,
    '&:not(:first-of-type)': {
      marginLeft: 8,
    },
  },

  '& .icon-btn-eye': {
    border: `solid 1px ${alpha(theme.palette.primary.main, 1.0)}`,
    color: alpha(theme.palette.primary.main, 1.0),
  },

  '&:hover': {
    '& .icon-btn': {
      border: `solid 1px #F84E4E`,
      color: '#F84E4E',
    },

    '& .icon-btn-eye': {
      border: `solid 1px ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
}));

const ActivitySchedule = ({ product_Id, product, purchaseOptions, slug }) => {
  const { messages } = useIntl();
  const isMobile = useMediaQuery('(max-width:836px)');
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const infoViewActionsContext = useInfoViewActionsContext();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const [isSubDialogOpen, setIsSubDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(null);
  const [dateError, setDateError] = useState('');
  // Regex to validate date in YYYY-MM-DD format
  const validateDate = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };
  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setStartDate(value);

    // Validate date format and check if it's not a past date
    if (value) {
      if (!validateDate(value)) {
        setDateError('Invalid date format.');
      } else {
        const enteredDate = new Date(value);
        const currentDate = new Date();

        // Reset the time portion of the current date for comparison
        currentDate.setHours(0, 0, 0, 0);

        if (enteredDate < currentDate) {
          setDateError('Date cannot be in the past.');
        } else {
          setDateError('');
        }
      }
    } else {
      setDateError('');
    }
  };
  const handleDialogClose = () => {
    setIsSubDialogOpen(false);
  };
  const selectedTheme = productThemes.find(
    (theme) => theme.title === product?.displaySettings?.product_theme,
  );
  const fetchSettings = async () => {
    try {
      setLoading(true);

      const response = await jwtAxios.get(
        `/activity-session/product/all-sessions/${product_Id}`,
        {
          params: {
            page,
            search,
            user_id: user?.id,
          },
        },
      );
      const { data } = response;
      const updatedData = data.list.map((data) => ({
        ...data,
        activity_name: data.activity_display_name
          ? data.activity_display_name
          : data.activity_name,
      }));
      setList(updatedData);
      setTotal(data.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [page, search]);

  const onSearchActivity = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };
  const onPageChange = (event, value) => {
    setPage(value);
  };
  const handleSignUp = async (user_id, id, product_id) => {
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/user-session`, {
        user_id: user_id,
        activity_session_id: id,
        product_id: product_id,
      });
      fetchSettings();
      infoViewActionsContext.showMessage(`Signed up to session successfully`);
    } catch (error) {
      infoViewActionsContext.fetchError(error.response.data.error);
    }
  };

  const handleWaitlist = async (user_id, id, product_id) => {
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/user-waitlist`, {
        user_id: user_id,
        activity_session_id: id,
        product_id: product_id,
      });
      fetchSettings();
      infoViewActionsContext.showMessage(
        `Session added to waitlist successfully`,
      );
    } catch (error) {
      infoViewActionsContext.fetchError(error.response.data.error);
    }
  };
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState('desc');
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = [...list].slice().sort((a, b) => {
    let valueA = a[orderBy];
    let valueB = b[orderBy];

    // Handle null and undefined values
    if (valueA === null && valueB === null) return 0;
    if (valueA === null || valueA === undefined) return 1;
    if (valueB === null || valueB === undefined) return -1;

    // Convert values to numbers if possible
    const numA = Number(valueA);
    const numB = Number(valueB);

    // Check if values are strings
    const isStringA = isNaN(numA);
    const isStringB = isNaN(numB);

    if (isStringA && isStringB) {
      // Compare strings
      if (order === 'asc') {
        return valueA.localeCompare(valueB); // String comparison
      } else {
        return valueB.localeCompare(valueA); // Reverse string comparison
      }
    } else if (isStringA || isStringB) {
      // One of the values is a string, consider it greater
      return isStringA ? 1 : -1;
    } else {
      // Both values are numbers, perform numerical comparison
      return order === 'asc' ? numA - numB : numB - numA;
    }
  });

  const [popupOpen, setPopupOpen] = useState(false);

  const handlePopUp = (itemId) => {
    setPopupOpen(true);
  };

  const handleCancelPopUp = () => {
    setPopupOpen(false);
  };
  const onAddToCard = async (
    monthly_price,
    payment_plan_id,
    product_purchase_id,
    startDate = null,
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (product?.expiry_date) {
      const productExpiryDate = new Date(product?.expiry_date?.split('T')[0]);
      productExpiryDate.setHours(0, 0, 0, 0);
      if (currentDate > productExpiryDate) {
        setIsSubDialogOpen(false); // Close the dialog
        infoViewActionsContext.fetchError('Product is expired');
        return;
      }
    }
    const purchaseOption = purchaseOptions.find(
      (purchaseOption) => purchaseOption.id === product_purchase_id,
    );
    if (purchaseOption?.estimated_offline_purchase_to) {
      const purchaseOptionExpiryDate = new Date(
        purchaseOption?.estimated_offline_purchase_to.split('T')[0],
      );
      purchaseOptionExpiryDate.setHours(0, 0, 0, 0);
      if (currentDate > purchaseOptionExpiryDate) {
        if (isSubDialogOpen) {
          // Check if the dialog is currently open
          setIsSubDialogOpen(false); // Close the dialog
        }
        infoViewActionsContext.fetchError('Purchase option is expired');
        return;
      }
    }
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/cart/`, {
        product_id: product.id,
        quantity: 1,
        item_price: monthly_price,
        ...(payment_plan_id && { payment_plan_id }),
        ...(product_purchase_id && { product_purchase_id }),
        ...(payment_plan_id && { sub_start_date: startDate }),
        pay_at_once: Boolean(purchaseOption?.pay_at_once) || false,
      });
      infoViewActionsContext.showMessage(
        `${product.product_name} added to cart successfully`,
      );
      navigate('/cart');
    } catch (error) {
      infoViewActionsContext.fetchError(error?.response?.data?.error);
    }
  };
  const getButtonProps = (selectedTheme, isMobile) => ({
    variant: 'outlined',
    size: 'small',
    sx: {
      minWidth: '80px',
      height: isMobile ? '40px' : '25px',
      borderColor: selectedTheme ? selectedTheme.primary.main : '#2196f3',
      '&:hover': {
        borderColor: selectedTheme
          ? alpha(selectedTheme.primary.main, 0.8)
          : alpha('#2196f3', 0.8),
      },
      color: selectedTheme ? selectedTheme.primary.main : 'primary',
    },
  });

  const renderButton = (condition, text, onClick, buttonProps) =>
    condition ? (
      <Button {...buttonProps} onClick={onClick}>
        {text}
      </Button>
    ) : (
      ''
    );

  const handleUserAction = (
    item,
    isGreaterThanToday,
    selectedTheme,
    isMobile,
    slug,
    user,
  ) => {
    const buttonProps = getButtonProps(selectedTheme, isMobile);
    const isRenderSignUpButton =
      !item?.user_session_id &&
      !item?.user_waitlist_id &&
      item?.user_session_count < item?.max_attendees &&
      isGreaterThanToday;
    const isRenderWiatListButton =
      !item?.disable_waitlist && isGreaterThanToday;
    if (item?.user_product_id) {
      if (!item?.user_session_id && !item?.user_waitlist_id) {
        return (
          <>
            {renderButton(
              isRenderSignUpButton,
              'Signup',
              () =>
                handleSignUp(
                  item?.child_id || item?.user_id,
                  item.id,
                  item.product_id,
                ),
              buttonProps,
            )}
            {!isRenderSignUpButton &&
              renderButton(
                isRenderWiatListButton,
                'Waitlist',
                () =>
                  handleWaitlist(
                    item?.child_id || item?.user_id,
                    item.id,
                    item.product_id,
                  ),
                buttonProps,
              )}
          </>
        );
      }
      return null;
    } else {
      return (
        <Button
          {...buttonProps}
          onClick={() => {
            if (slug && !user) {
              window.open(`/signin?refurl=/products/${slug}`, '_blank');
            } else {
              handlePopUp();
            }
          }}
        >
          Buy Now
        </Button>
      );
    }
  };
  const planType = (data) => {
    let label = data.purchase_price;
    if (data.pay_at_once) {
      label = `pay for ${data.plan_type} months  $${data.monthly_price}`;
    } else if (data.payment_plan_label) {
      label = `${data.payment_plan_label}  $${data.monthly_price}`;
    }
    return label;
  };
  return (
    <>
      <AppsHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 1,
          }}
        >
          <AppSearchBar
            iconPosition='right'
            overlap={false}
            onChange={onSearchActivity}
            placeholder={messages['common.searchHere']}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: 'auto',
            }}
          >
            <Hidden smDown>
              <AppsPagination
                rowsPerPage={10}
                count={total}
                page={page}
                onPageChange={onPageChange}
              />
            </Hidden>
          </Box>
        </Box>
      </AppsHeader>
      <Hidden smUp>
        <AppsPagination
          rowsPerPage={10}
          count={total}
          page={page}
          onPageChange={onPageChange}
        />
      </Hidden>
      <AppScrollbar>
        <Table>
          <TableHeader
            className='session-table-header'
            sx={{
              borderBottom: '0 none',
              '& .tableCell': {
                fontSize: 13,
                padding: 2,
                fontWeight: Fonts.BOLD,
                backgroundColor: '#fff',
                color: '#000',
                '&:first-of-type': {
                  pl: 5,
                },
                '&:last-of-type': {
                  pr: 5,
                },
              },
            }}
          >
            <TableCell
              className='tableCell'
              align='left'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === 'activity_name'}
                direction={orderBy === 'activity_name' ? order : 'asc'}
                onClick={() => handleSort('activity_name')}
              >
                Activity Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              className='tableCell'
              align='center'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === 'start_date'}
                direction={orderBy === 'start_date' ? order : 'asc'}
                onClick={() => handleSort('start_date')}
              >
                Start Date
              </TableSortLabel>
            </TableCell>
            <TableCell
              className='tableCell'
              align='center'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === 'start_time'}
                direction={orderBy === 'start_time' ? order : 'asc'}
                onClick={() => handleSort('start_time')}
              >
                Start Time
              </TableSortLabel>
            </TableCell>
            <TableCell className='tableCell' align='left'>
              <TableSortLabel
                active={orderBy === 'user_session_count'}
                direction={orderBy === 'user_session_count' ? order : 'asc'}
                onClick={() => handleSort('user_session_count')}
              >
                Max Attendees
              </TableSortLabel>
            </TableCell>
            <TableCell
              className='tableCell'
              align='center'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === 'location_name'}
                direction={orderBy === 'location_name' ? order : 'asc'}
                onClick={() => handleSort('location_name')}
              >
                Location
              </TableSortLabel>
            </TableCell>
            <TableCell
              className='tableCell'
              align='center'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === 'resource_name'}
                direction={orderBy === 'resource_name' ? order : 'asc'}
                onClick={() => handleSort('resource_name')}
              >
                Resource
              </TableSortLabel>
            </TableCell>

            <TableCell
              align='right'
              sx={{ fontSize: '15px', fontWeight: 'bold' }}
            >
              Sign Up
            </TableCell>
          </TableHeader>
          {loading ? (
            <AppLoader />
          ) : (
            <TableBody>
              {sortedData && sortedData.length > 0 ? (
                sortedData.map((item) => {
                  const formattedStartTime =
                    item.start_time &&
                    format(
                      new Date(`1970-01-01T${item.start_time}`),
                      'hh:mm a',
                      {
                        locale: enUS,
                      },
                    );

                  let startDate = new Date(
                    `${item?.start_date.substring(0, 10)}T12:01:00`,
                  );

                  const today = new Date();
                  const startTimeParts = item.start_time?.split(':');
                  const eventStartTime = new Date(startDate);
                  eventStartTime.setHours(parseInt(startTimeParts[0]));
                  eventStartTime.setMinutes(parseInt(startTimeParts[1]));
                  eventStartTime.setSeconds(parseInt(startTimeParts[2]));
                  const isGreaterThanToday = eventStartTime > today;
                  return (
                    <TableRow key={item.program_service_settings_id}>
                      <StyledTableCell align='left'>
                        <Box className='textwrap'>{item.activity_name}</Box>
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {item?.start_date
                          ? formatDateTimeRange(new Date(startDate))
                          : '-'}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {formattedStartTime}
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingLeft: '20px' }}>
                        {item?.user_session_count} of{' '}
                        {item?.max_attendees ? item?.max_attendees : 0}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {item?.location_name?.length > 20 ? (
                          <Tooltip title={item.location_name}>
                            {item.location_name.substring(0, 20) + '...'}
                          </Tooltip>
                        ) : (
                          item.location_name
                        )}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {item?.resource_name?.length > 20 ? (
                          <Tooltip title={item.resource_name}>
                            {item.resource_name.substring(0, 20) + '...'}
                          </Tooltip>
                        ) : (
                          item.resource_name
                        )}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {handleUserAction(
                          item,
                          isGreaterThanToday,
                          selectedTheme,
                          isMobile,
                          slug,
                          user,
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={7}>
                    No data available
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </AppScrollbar>
      <Dialog
        open={popupOpen}
        onClose={handleCancelPopUp}
        // fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          {/* <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            Are you sure you want to delete this package?
          </Typography> */}
          <Grid
            item
            xm={12}
            sm={12}
            md={12}
            className='purchase-options'
            id='productpurchaseoption'
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {purchaseOptions?.length > 0 ? (
                <Box
                  sx={{
                    marginBottom: 4,
                  }}
                >
                  <DialogTitle>
                    <Typography variant='h2' sx={{ textAlign: 'center' }}>
                      Purchase Options
                    </Typography>
                  </DialogTitle>
                </Box>
              ) : (
                <Box
                  sx={{
                    marginBottom: 4,
                  }}
                >
                  <Typography variant='h3'>
                    No Purchase Options Available
                  </Typography>
                </Box>
              )}
              <Box
                className='purchase-buttons'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {purchaseOptions?.filter(
                  (option) => option.membership_expiration_visible === 1,
                ).length > 0
                  ? purchaseOptions
                      .filter(
                        (option) => option.membership_expiration_visible === 1,
                      )
                      .map((data, index) => (
                        <Grid
                          key={data.id}
                          sx={{
                            display: 'block',
                            marginBottom: 5,
                            marginRight: 2,
                            textAlign: 'center',
                          }}
                        >
                          {user?.role[0] === 'admin' ? (
                            <div>
                              <Typography
                                variant='h3'
                                sx={{ textAlign: 'center', marginBottom: 2 }}
                              >
                                Credits:
                                {data.check_here_for_unlimited_credits
                                  ? ' ∞ '
                                  : data.credit_amount}
                              </Typography>
                              <Button
                                variant='contained'
                                color='primary'
                                style={{
                                  maxWidth: 200,
                                  minWidth: 140,
                                  maxHeight: 50,
                                  backgroundColor: selectedTheme
                                    ? selectedTheme.primary.main
                                    : '#2196f3',
                                  margin: 'auto',
                                }}
                              >
                                {(() => {
                                  return planType(data);
                                })()}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Typography
                                variant='h3'
                                sx={{ textAlign: 'center', marginBottom: 2 }}
                              >
                                Credits:
                                {data.check_here_for_unlimited_credits
                                  ? ' ∞ '
                                  : data.credit_amount}
                              </Typography>
                              <Button
                                variant='contained'
                                color='primary'
                                style={{
                                  maxWidth: 200,
                                  minWidth: 140,
                                  maxHeight: 50,
                                  backgroundColor: selectedTheme
                                    ? selectedTheme.primary.main
                                    : '#2196f3',
                                }}
                                onClick={() => {
                                  if (data?.purchase_price === '0.00') {
                                    // Open popup for free plans
                                    setSelectedPurchaseOption(data);
                                    setIsSubDialogOpen(true);
                                  } else {
                                    // Add to cart for paid plans
                                    onAddToCard(
                                      data?.monthly_price === '0.00'
                                        ? data?.purchase_price
                                        : data?.monthly_price,
                                      data?.payment_plan_id,
                                      data?.id,
                                    );
                                  }
                                }}
                              >
                                {(() => {
                                  return planType(data);
                                })()}
                              </Button>
                            </div>
                          )}
                        </Grid>
                      ))
                  : 'No Purchase Options Available'}
              </Box>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
      <SubscriptionStartDateDialog
        isOpen={isSubDialogOpen}
        onClose={handleDialogClose}
        onConfirm={onAddToCard}
        startDate={startDate}
        onStartDateChange={handleStartDateChange}
        selectedPurchaseOption={selectedPurchaseOption} // Pass the data here
        dateError={dateError}
        setStartDate={setStartDate}
      />
    </>
  );
};

ActivitySchedule.propTypes = {
  product_Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired, // Accepts either a string or a number
  product: PropTypes.object.isRequired, // Assuming product is an object
  purchaseOptions: PropTypes.array.isRequired, // Array of objects
  slug: PropTypes.string.isRequired, // Slug must be a string
};
export default ActivitySchedule;
