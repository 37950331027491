import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import productThemes from '@projectK/constants/ProductThemes';
import jwtAxios from '@projectK/services/auth/JWT';
import { useInfoViewActionsContext } from '@projectK/context';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthUser } from '@projectK/hooks/AuthHooks';
import EditIcon from '@mui/icons-material/Edit';
import ImageUpload from './ImageUpload';
import SubscriptionStartDateDialog from '../SubscriptionStartDateDialog';
const ProductView = ({ product, purchaseOptions, fetchData, slug }) => {
  const { user } = useAuthUser();
  const { productId } = useParams();
  const selectedTheme = productThemes.find(
    (theme) => theme.title === product?.displaySettings?.product_theme,
  );
  const infoViewActionsContext = useInfoViewActionsContext();
  const navigate = useNavigate();
  const [isSubDialogOpen, setIsSubDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(null);
  const handleDialogClose = () => {
    setIsSubDialogOpen(false);
  };
  const [dateError, setDateError] = useState('');
  // Regex to validate date in YYYY-MM-DD format
  const validateDate = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };
  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setStartDate(value);

    // Validate date format and check if it's not a past date
    if (value) {
      if (!validateDate(value)) {
        setDateError('Invalid date format.');
      } else {
        const enteredDate = new Date(value);
        const currentDate = new Date();

        // Reset the time portion of the current date for comparison
        currentDate.setHours(0, 0, 0, 0);

        if (enteredDate < currentDate) {
          setDateError('Date cannot be in the past.');
        } else {
          setDateError('');
        }
      }
    } else {
      setDateError('');
    }
  };

  const onAddToCard = async (
    monthly_price,
    payment_plan_id,
    product_purchase_id,
    startDate = null,
  ) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (product?.expiry_date) {
      const productExpiryDate = new Date(product?.expiry_date?.split('T')[0]);
      productExpiryDate.setHours(0, 0, 0, 0);
      if (currentDate > productExpiryDate) {
        if (isSubDialogOpen) {
          // Check if the dialog is currently open
          setIsSubDialogOpen(false); // Close the dialog
        }
        infoViewActionsContext.fetchError('Product is expired');
        return;
      }
    }
    const purchaseOption = purchaseOptions.find(
      (purchaseOption) => purchaseOption.id === product_purchase_id,
    );
    if (purchaseOption?.estimated_offline_purchase_to) {
      const purchaseOptionExpiryDate = new Date(
        purchaseOption?.estimated_offline_purchase_to.split('T')[0],
      );
      purchaseOptionExpiryDate.setHours(0, 0, 0, 0);

      if (currentDate > purchaseOptionExpiryDate) {
        infoViewActionsContext.fetchError('Purchase option is expired');
        return;
      }
    }
    try {
      // Make the POST request using jwtAxios
      await jwtAxios.post(`/cart/`, {
        product_id: product.id,
        quantity: 1,
        item_price: monthly_price,
        ...(payment_plan_id && { payment_plan_id }),
        ...(product_purchase_id && { product_purchase_id }),
        ...(payment_plan_id && { sub_start_date: startDate }),
        pay_at_once: Boolean(purchaseOption?.pay_at_once) || false,
      });
      infoViewActionsContext.showMessage(
        `${product.product_name} added to cart successfully`,
      );
      navigate('/cart');
    } catch (error) {
      infoViewActionsContext.fetchError(error?.response?.data?.error);
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let productName = product.product_name;

  const planType = (data) => {
    let label = data.purchase_price;
    if (data.pay_at_once) {
      label = `pay for ${data.plan_type} months  $${data.monthly_price}`;
    } else if (data.payment_plan_label) {
      label = `${data.payment_plan_label}  $${data.monthly_price}`;
    }
    return label;
  };
  return (
    <Grid container spacing={1}>
      <Grid item xm={12} sm={5} md={4} style={{ position: 'relative' }}>
        {product?.displaySettings?.product_image ? (
          <>
            <div className='p-img'>
              <img
                src={`${process.env.NX_CLOUD_FRONT_URL}/${product?.displaySettings?.product_image}`}
                alt='product'
              />
            </div>
            {user?.role[0] === 'admin' && (
              <Button
                variant='contained'
                color='primary'
                style={{
                  minWidth: 100,
                  maxHeight: 30,
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: selectedTheme
                    ? selectedTheme.primary.main
                    : '#2196f3',
                  paddingLeft: 0,
                }}
                className='edit-icon'
                onClick={handleEditClick}
              >
                <IconButton
                  size='small'
                  className='icon-btn icon-btn-eye'
                  sx={{ color: 'white' }}
                >
                  <EditIcon />
                </IconButton>
                Update Image
              </Button>
            )}
          </>
        ) : (
          <>
            <div className='p-img'>
              <img src='/assets/images/default-backgroud.png' alt='product' />
            </div>
            {user?.role[0] === 'admin' && (
              <Button
                variant='contained'
                color='primary'
                style={{
                  minWidth: 100,
                  maxHeight: 30,
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: selectedTheme
                    ? selectedTheme.primary.main
                    : '#2196f3',
                  paddingLeft: 0,
                }}
                className='edit-icon'
                onClick={handleEditClick}
              >
                <IconButton
                  size='small'
                  className='icon-btn icon-btn-eye'
                  sx={{ color: 'white' }}
                >
                  <EditIcon />
                </IconButton>
                Update Image
              </Button>
            )}
          </>
        )}
      </Grid>
      <Grid item xm={12} sm={7} md={8} className='details'>
        {user?.role[0] === 'admin' && (
          <Button
            variant='contained'
            color='primary'
            style={{
              minWidth: 100,
              alignItems: 'flex-start',
              lineHeight: '1.75',
              backgroundColor: selectedTheme
                ? selectedTheme.primary.main
                : '#2196f3',
              paddingLeft: 0,
            }}
            onClick={() => navigate(`/admin/service/settings/${productId}`)}
          >
            <IconButton
              size='small'
              className='icon-btn icon-btn-eye'
              sx={{ color: 'white' }}
            >
              <EditIcon />
            </IconButton>
            Manage&nbsp; <span className='wordbreak'>{productName}</span>
          </Button>
        )}
        <Box
          component='p'
          sx={{
            color: 'text.secondary',
            textAlign: 'justify',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                product.displaySettings?.product_description ||
                'No description found',
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xm={12}
        sm={12}
        md={12}
        className='purchase-options'
        id='productpurchaseoption'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {purchaseOptions.length > 0 ? (
            <Box sx={{ marginBottom: 4 }}>
              <Typography variant='h2'>Purchase Options</Typography>
            </Box>
          ) : (
            <Box sx={{ marginBottom: 4 }}>
              <Typography variant='h3'>
                No Purchase Options Available
              </Typography>
            </Box>
          )}
          <Box className='purchase-buttons'>
            {purchaseOptions.filter(
              (option) => option.membership_expiration_visible === 1,
            ).length > 0
              ? purchaseOptions
                  .filter(
                    (option) => option.membership_expiration_visible === 1,
                  )
                  .map((data, index) => {
                    return (
                      <Grid
                        key={data.id}
                        sx={{
                          display: 'block',
                          marginBottom: 5,
                          textAlign: 'center',
                        }}
                      >
                        {user?.role[0] === 'admin' ? (
                          <div>
                            <Typography
                              variant='h3'
                              sx={{ textAlign: 'center', marginBottom: 2 }}
                            >
                              Credits:
                              {(() => {
                                if (data.check_here_for_unlimited_credits) {
                                  return ' ∞ ';
                                } else {
                                  return data.credit_amount;
                                }
                              })()}
                            </Typography>

                            <Button
                              variant='contained'
                              color='primary'
                              style={{
                                maxWidth: 200,
                                minWidth: 140,
                                maxHeight: 50,
                                backgroundColor: selectedTheme
                                  ? selectedTheme.primary.main
                                  : '#2196f3',
                                margin: 'auto',
                              }}
                            >
                              {(() => {
                                return planType(data);
                              })()}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Typography
                              variant='h3'
                              sx={{ textAlign: 'center', marginBottom: 2 }}
                            >
                              Credits:
                              {(() => {
                                if (data.check_here_for_unlimited_credits) {
                                  return ' ∞ ';
                                } else {
                                  return data.credit_amount;
                                }
                              })()}
                            </Typography>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{
                                maxWidth: 240,
                                minWidth: 140,
                                maxHeight: 50,
                                backgroundColor: selectedTheme
                                  ? selectedTheme.primary.main
                                  : '#2196f3',
                              }}
                              onClick={() => {
                                if (slug) {
                                  window.open(
                                    `/signin?refurl=/products/${slug}`,
                                    '_blank',
                                  );
                                } else if (data?.purchase_price === '0.00') {
                                  setSelectedPurchaseOption(data);
                                  setIsSubDialogOpen(true);
                                } else {
                                  // Add to cart for paid plans
                                  onAddToCard(
                                    data?.monthly_price === '0.00'
                                      ? data?.purchase_price
                                      : data?.monthly_price,
                                    data?.payment_plan_id,
                                    data?.id,
                                  );
                                }
                              }}
                            >
                              {(() => {
                                return planType(data);
                              })()}
                            </Button>
                          </div>
                        )}
                      </Grid>
                    );
                  })
              : 'No Purchase Options Available'}
          </Box>
        </Box>
      </Grid>
      <SubscriptionStartDateDialog
        isOpen={isSubDialogOpen}
        onClose={handleDialogClose}
        onConfirm={onAddToCard}
        startDate={startDate}
        onStartDateChange={handleStartDateChange}
        selectedPurchaseOption={selectedPurchaseOption} // Pass the data here
        dateError={dateError}
        setStartDate={setStartDate}
      />
      <ImageUpload
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        settings={product.displaySettings}
        product_Id={product.id}
        fetchData={fetchData}
        selectedTheme={selectedTheme}
      />
    </Grid>
  );
};

export default ProductView;

ProductView.propTypes = {
  product: PropTypes.object,
  purchaseOptions: PropTypes.array,
  fetchData: PropTypes.func,
  slug: PropTypes.string,
};
